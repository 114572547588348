import React from 'react'
import Meta from '../components/layout/meta'
import { Link } from '../components/UI'
import { Layout } from '../components/layout'
import { TextContainer } from '../utils'
import { reviewStructuredData } from '../components/layout/meta/schema-org'

const PressPage = () => (
    <Layout>
        <Meta
            keywords={['zzp', 'boekhouden', 'pers', 'tellow']}
            title="Pers - Tellow"
            description="Wij willen zzp'ers de vrijheid geven om te ondernemen"
            path="/pers"
            jsonLD={reviewStructuredData}
        />
        <TextContainer>
            <h1>Pers</h1>
            <h2>Links</h2>
            <ul>
                <li>
                    <Link to="https://www.nu.nl/nucheckt/5255645/nucheckt-zzpers-gelukkiger-dan-werknemers-in-loondienst.html">Nu.nl</Link>
                </li>
                <li>
                    <Link to="https://www.finextra.com/newsarticle/32647/rabobank-spins-off-moonshot-book-keeping-app-tellow">Finextra</Link>
                </li>
                <li>
                    <Link to="https://www.emerce.nl/wire/boekhoudtool-tellow-binnenkort-beschikbaar-alle-zzpers">Emerce</Link>
                </li>
            </ul>
            <h2>Downloads</h2>
            <ul>
                <li>
                    <Link to="/assets/press/nl_logokit.zip">Tellow logo kit</Link>
                </li>
                <li>
                    <Link to="/assets/press/nl_logokit-accountants.zip">Tellow logo kit voor boekhouders</Link>
                </li>
                <li>
                    <Link to="/assets/press/nl_app-screens.zip">Tellow app schermen</Link>
                </li>
                <li>
                    <Link to="/assets/press/nl_press-photos.zip">Tellow fotografie materiaal</Link>
                </li>
            </ul>
        </TextContainer>
    </Layout>
)

export default PressPage
